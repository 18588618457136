@import url(https://fonts.googleapis.com/css2?family=Montserrat);
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat', sans-serif !important; }
  body input {
    font-family: 'Montserrat', sans-serif !important; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  body .loading-screen {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    body .loading-screen img {
      width: 50%; }
    body .loading-screen .spinner {
      margin: 10px auto 0;
      width: 70px;
      text-align: center; }
    body .loading-screen .spinner div {
      width: 18px;
      height: 18px;
      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both; }
    body .loading-screen .spinner .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
      background-color: #1f459e; }
    body .loading-screen .spinner .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
      background-color: #f15b2d; }
    body .loading-screen .spinner .bounce3 {
      background-color: #f2a437; }

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

#nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px 20px;
  height: 70px;
  box-shadow: 0px 5px 20px rgba(91, 91, 91, 0.25);
  border-radius: 0px 0px 14px 14px; }
  #nav-bar img {
    height: 32px; }
  #nav-bar a {
    cursor: pointer; }
  #nav-bar .dropdown {
    position: relative;
    display: inline-block; }
  #nav-bar .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1; }
  #nav-bar .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block; }
  #nav-bar .dropdown-content a:hover {
    background-color: #f1f1f1; }
  #nav-bar .dropdown:hover .dropdown-content {
    display: block; }
  #nav-bar .dropdown:hover .dropbtn {
    background-color: #3e8e41; }

body .loading-screen {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  body .loading-screen img {
    width: 50%; }
  body .loading-screen .spinner {
    margin: 10px auto 0;
    width: 70px;
    text-align: center; }
  body .loading-screen .spinner div {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both; }
  body .loading-screen .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
    background-color: #1f459e; }
  body .loading-screen .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    background-color: #f15b2d; }
  body .loading-screen .spinner .bounce3 {
    background-color: #f2a437; }

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

