body {
    .loading-screen {
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
            width: 50%;
        }
        .spinner {
            margin: 10px auto 0;
            width: 70px;
            text-align: center;
        }
          
        .spinner{
            div {
                width: 18px;
                height: 18px;
            
                border-radius: 100%;
                display: inline-block;
                -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
                animation: sk-bouncedelay 1.4s infinite ease-in-out both;
            }

            .bounce1 {
                -webkit-animation-delay: -0.32s;
                animation-delay: -0.32s;
                background-color: rgb(31, 69, 158);
            }
        
            .bounce2 {
                -webkit-animation-delay: -0.16s;
                animation-delay: -0.16s;
                background-color: rgb(241, 91, 45);
            }

            .bounce3 {
                background-color: rgb(242, 164, 55);
            }
            
            @-webkit-keyframes sk-bouncedelay {
                0%, 80%, 100% { -webkit-transform: scale(0) }
                40% { -webkit-transform: scale(1.0) }
            }
            
            @keyframes sk-bouncedelay {
                0%, 80%, 100% { 
                    -webkit-transform: scale(0);
                    transform: scale(0);
                } 40% { 
                    -webkit-transform: scale(1.0);
                    transform: scale(1.0);
                }
            }
        }
    }
}
