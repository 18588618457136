#nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 10px 20px;
    height: 70px;
    box-shadow: 0px 5px 20px rgba(91, 91, 91, 0.25);
    border-radius: 0px 0px 14px 14px;
    
    img {
        height: 32px;
    }

    a {
        cursor: pointer;
    }

    .dropdown {
        position: relative;
        display: inline-block;
    }

    .dropdown-content {
        display: none;
        position: absolute;
        right: 0;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
    }
      
    .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }
      
    .dropdown-content a:hover {background-color: #f1f1f1;}
      
    .dropdown:hover .dropdown-content {
        display: block;
    }
      
    .dropdown:hover .dropbtn {
        background-color: #3e8e41;
    }
}
